import { Type } from "class-transformer";
import { TicketStatus } from "../call-center/type/ticket-status.enum";
import { ClientSummaryOutDTO } from "../client/client-summary.out.dto";
import { UserOutDTO } from "../user/user.out";
import { InterventionDataDTO } from "./intervention-data.dto";
import { InterventionStatus } from "./type/intervention-status.enum";

export class InterventionSummaryListOutDTO {
  id!: string;
  ref!: number;

  @Type(() => Date)
  changedAt!: Date;

  @Type(() => ClientSummaryOutDTO)
  client?: ClientSummaryOutDTO;

  type!: string;

  @Type(() => UserOutDTO)
  assignedUsers?: UserOutDTO[];

  assignedCategories?: string[];

  description?: string | null;
  status!: TicketStatus;
  priority!: number;

  @Type(() => InterventionDataDTO)
  data!: InterventionDataDTO;

  get colorFromInterventionStatus() {
    if (this.data?.interventionStatus) {
      switch (this.data?.interventionStatus) {
        case InterventionStatus.InstallationRequested:
          return "purple accent-3 white--text";
        case InterventionStatus.InstallationToDo:
          return "green accent-3 black--text";
        case InterventionStatus.InstallationInProgress:
          return "blue white--text";
        case InterventionStatus.AddKiosk:
          return "yellow black--text";
        case InterventionStatus.SwappToDo:
          return "amber black--text";
        case InterventionStatus.ToUninstall:
          return "blue-grey white--text";
        case InterventionStatus.ShopClosed:
          return "black white--text";
        case InterventionStatus.Support:
          return "red darken-1 white--text";
        case InterventionStatus.InstallationCompleted:
          return "lime darken-4 white--text";
        case InterventionStatus.BToBetMigration:
          return "pink darken-2 white--text";
        case InterventionStatus.BToBetMigrationDone:
          return "indigo darken-2 white--text";
        default:
          return "";
      }
    }
    return "";
  }

  get lightColorFromInterventionStatus() {
    if (this.data?.interventionStatus) {
      switch (this.data?.interventionStatus) {
        case InterventionStatus.InstallationRequested:
          return "purple accent-1";
        case InterventionStatus.InstallationToDo:
          return "green accent-1 black--text";
        case InterventionStatus.InstallationInProgress:
          return "blue accent-1";
        case InterventionStatus.AddKiosk:
          return "yellow accent-1 black--text";
        case InterventionStatus.SwappToDo:
          return "amber accent-1 black--text";
        case InterventionStatus.ToUninstall:
          return "blue-grey lighten-4 black--text";
        case InterventionStatus.ShopClosed:
          return "black white--text";
        case InterventionStatus.Support:
          return "red accent-1";
        case InterventionStatus.InstallationCompleted:
          return "lime darken-2";
        case InterventionStatus.BToBetMigration:
          return "pink lighten-3 black--text";
        case InterventionStatus.BToBetMigrationDone:
          return "indigo lighten-3 black--text";
        default:
          return "";
      }
    }
    return "";
  }
}
